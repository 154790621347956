import * as React from "react";
import * as ReactDOM from "react-dom";
import registerServiceWorker from "./registerServiceWorker";
import configureStore from './store'
import MainRouter from "./MainRouter"
import "./index.css";
import { runWithAdal } from 'react-adal';
import { authContext } from './utils/Authentication';


const store = configureStore();
runWithAdal(authContext, () => {
    ReactDOM.render(
        <MainRouter store={store}/>,
        document.getElementById("root") as HTMLElement
    );
}, false);
registerServiceWorker();
