import * as React from "react";
import { IData as  OutreachDoelgroep } from "src/store/outreach/OutreachDoelgroepen";
import { IData } from "src/store/outreach/OutreachItem";
import * as Amdd from "../../components/selectie/AllMedewerkersDropDown" ;
import { emptyMedewerker } from "../../components/selectie/AlleMedewerkersDropDown.args";
import { IData as outreachSettings } from "src/store/outreach/outreachSettings";



interface IProps {
    details: IData;
    settings: outreachSettings;
    detailUpdated: (details: IData) => void;
    doelgroepen: OutreachDoelgroep[];
}

export type AllProps = IProps & Amdd.AllProps;

export const OutreachFullForm = (props: AllProps) => {
    
    const [details, setDetails] = React.useState(props.details);

    const update = (det: IData) => {
        setDetails(det);
        props.detailUpdated(det);
    }

    return (
        <table className={'table table-striped table-hover'}>
            <tbody>
                <tr><th scope={"row"}>Medewerker:</th>
                    <td>
                        <Amdd.AlleMedewerkersDropDown 
                        medewerkers={props.medewerkers} 
                        fetchMedewerkers={props.fetchMedewerkers} 
                        selectedMedewerker={{...props.selectedMedewerker, id: details.medewerkerId, fullName: details.medewerker}} 
                        updateMedewerker={(med) => { if (med.id !== "") { update({...details, medewerker: med.fullName, medewerkerId: med.id})}; return props.updateMedewerker(med)}} />
                    </td>
                </tr>
                <tr><th scope={"row"}>Datum:</th><td><input min={props.settings.minDate.toISOString().split('T')[0]} max={props.settings.maxDate.toISOString().split('T')[0]} pattern="\d{4}-\d{2}-\d{2}" type="date" onChange={e => new Date(e.target.value) > props.settings.minDate && new Date(e.target.value) < props.settings.maxDate && update({...details, datum: new Date(e.target.value) })} value={details.datum.toISOString().split('T')[0]}/></td></tr>
                <tr><th scope={"row"}>Organisatie:</th><td><input required={true} type={"text"} value={details.organisatie} onChange={e => update({...details, organisatie: e.target.value})} /></td></tr>
                <tr><th scope={"row"}>Doelgroep:</th><td><select value={details.doelgroepId} onChange={e => update({...details, doelgroepId: e.target.value})}>
                    {props.doelgroepen.filter(dg => dg.id === details.doelgroepId).length === 0 && <option disabled={true} value={details.doelgroepId} key={details.doelgroepId}>{details.doelgroep}{details.doelgroepDetail ? " - " + details.doelgroepDetail : ""}</option>} 
                    {props.doelgroepen.map(dg => <option value={dg.id} key={dg.id}>{dg.naam}</option> )}
                </select> </td></tr>
                {
                    (
                        props.doelgroepen.filter(dg => dg.id === details.doelgroepId).length !== 0 &&
                        props.doelgroepen.filter(dg => dg.id === details.doelgroepId)[0].enableOmschrijving
                    ) &&
                    <tr>
                        <th scope={"row"}>Doelgroep Detail:</th><td><input required={true} type={"text"} value={details.doelgroepDetail} onChange={e => update({...details, doelgroepDetail: e.target.value})} /></td>
                    </tr> 
                }
                <tr><th scope={"row"}>Thema:</th><td><input type={"text"} required={true} value={details.thema} onChange={e => update({...details, thema: e.target.value})} /></td></tr>
                <tr><th scope={"row"}>Aantal aanwezigen Professioneel:</th><td><input min={0} name={'aantalAanwezigProfessioneel'} type={"number"} onChange={e => update({...details, aantalAanwezigProfessioneel: +e.target.value})} value={details.aantalAanwezigProfessioneel} /></td></tr>
                <tr><th scope={"row"}>Aantal aanwezigen Niet-professioneel:</th><td><input min={0} name={'aantalAanwezigNietProfessioneel'} type={"number"} value={details.aantalAanwezigNietProfessioneel} onChange={e => update({...details, aantalAanwezigNietProfessioneel: +e.target.value})} /></td></tr>
                <tr><th scope={"row"}>Aantal aanwezigen:</th><td>{details.aantalAanwezigNietProfessioneel + details.aantalAanwezigProfessioneel}</td></tr>
            </tbody>
        </table> 
         );
}