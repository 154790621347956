// React-Redux Stuff
import * as React from "react";
import { Dispatch } from "redux"
import { connect } from "react-redux"
import { IApplicationState } from "../../../store"

// Local Redux State Stuff
import * as InternalReports from "../../../store/rapporten/internal"
import ContactenPerMedewerkerPerMaand from "../../../entities/reports/ContactenPerMedewerkerPerMaand"
import { IReportData } from "../../../entities/reports/IReportData"

// Components
import Medewerker from "../../../entities/Medewerker"
// Import React Table
import "react-table/react-table.css";

import StdRapport from '../common/StandaardRapportFull';


interface IPropsFromState {
    rapport: IReportData<ContactenPerMedewerkerPerMaand>;
    selectedUser: Medewerker;
    startDate: Date;
    endDate: Date;
}
const mapStateToProps = ({ rapporten, selectie }: IApplicationState) => ({
    rapport: rapporten.internal.ContactenPerMedewerkerPerMaand,
    selectedUser: selectie.selectedMedewerker,
    startDate: selectie.selectedDateRange.startDate,
    endDate: selectie.selectedDateRange.endDate
});


interface IPropsFromDispatch {
    FetchReport: typeof InternalReports.ActionCreator.FetchContactenPerMedewerkerPerMaand,
    DownloadReport: typeof InternalReports.ActionCreator.FetchContactenPerMedewerkerPerMaandXlsx
}
const mapDispatchToProps = (dispatch: Dispatch) => ({
    FetchReport: (userId: string, startDate: Date, endDate: Date) => dispatch(InternalReports.ActionCreator.FetchContactenPerMedewerkerPerMaand(userId, startDate, endDate)),
    DownloadReport: (userId: string, startDate: Date, endDate: Date) => dispatch(InternalReports.ActionCreator.FetchContactenPerMedewerkerPerMaandXlsx(userId, startDate, endDate))
});


type AllProps = IPropsFromState & IPropsFromDispatch

class Rapport extends React.Component<AllProps> {

    constructor(props: any, context: any) {
        super(props, context);
    }

    public render() {
      return(
            <StdRapport
                title="Rapport contacten per medewerker per maand"
                selectDate={true}
                selectMedewerker={true}
                startDate={this.props.startDate}
                endDate={this.props.endDate}
                selectedUser={this.props.selectedUser}
                rapport={this.props.rapport}
                FetchReport={this.props.FetchReport}
                FetchReportXlsx={this.props.DownloadReport}
                columns={
                    [
                        {
                            Header: "Periode", columns:
                                [

                                    { Header: "Maand", accessor: "maand" },
                                    { Header: "Jaar", accessor: "jaar" }
                                ]
                        },
                        {
                            Header: "Medewerker", columns:
                                [
                                    { Header: "Medewerker", accessor: "medewerker" }
                                ]
                        },
                        {
                            Header: "Status", columns:
                                [
                                    { Header: "Dossiers", accessor: "aantalDossiers" },
                                    { Header: "Contacten", accessor: "aantalContacten" }
                                ]
                        },
                    ]
                }
            />
        );
    }

}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Rapport);
